import { useState } from 'react';

import { type CodeCanvasBlock } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { useLiveAsyncCall } from '../../../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { apiService } from '../../../../../services/api-service';
import { useAwaitFullScreenConfirmCancelModal } from '../../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../../ConfirmCancelModalContext/ModalWrapper';
import { type GameEditorStore } from '../../../../Game/GameEditorStore';
import { RefreshIcon } from '../../../../icons/RefreshIcon';
import { Loading } from '../../../../Loading';
import { useTrainingSlideEditor } from '../../hooks';
import { type TrainingSlideEditorProps } from '../../types';

const EXAMPLES = [
  {
    title: 'Course Introduction',
    description: `A slide to introduce a leadership workshop.

- Title: Leadership
- Subtitle: Build Skills for Team Success
- Author: Dr. Emma Richardson
`,
  },
  {
    title: 'Product Features',
    description: `A slide to introduce different learning approaches.

**Interactive Learning:**

- Group discussions that encourage sharing of diverse perspectives
- Hands-on activities that apply concepts to real-world situations
- Role-playing exercises that develop practical communication skills
- Problem-solving challenges that strengthen critical thinking abilities

**Self-Paced Learning:**

- Personalized study schedules that adapt to individual availability
- Digital resources accessible anytime from various devices
- Progress tracking that provides motivation through achievement
- Concept mastery through repeated practice and self-assessment
  `,
  },
  {
    title: 'Project Timeline',
    description: `A timeline showing the key phases of product development:

- Research & Planning: Market analysis and concept definition
- Design & Prototyping: Creating the initial product designs
- Development: Building the core functionality
- Testing & Refinement: Quality assurance and improvements
- Launch & Feedback: Release and gathering user responses
    `,
  },
];

export function CodeGeneratorModal(props: {
  userRequest: string;
  onCancel: () => void;
  onGenerate: (description: string) => void;
}) {
  const { userRequest, onCancel, onGenerate } = props;

  const [description, setDescription] = useState(userRequest);

  const handleGenerate = useLiveCallback(() => {
    onGenerate(description);
  });

  return (
    <div className='w-full h-full flex flex-col items-center gap-8'>
      <div className='font-bold text-2xl'>Make a slide with AI</div>

      <div className='w-full flex flex-col gap-2'>
        <label className='font-bold'>Describe your slide</label>
        <textarea
          className={`w-full h-40 field m-0 py-3`}
          placeholder={'A slide to...'}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className='flex flex-col gap-2 mt-2'>
          <span className='text-icon-gray text-sms'>Or try a sample:</span>
          <div className='flex gap-2'>
            {EXAMPLES.map((example, index) => (
              <button
                type='button'
                key={index}
                className='btn bg-[#4b4b4b] hover:brightness-110 text-icon-gray text-xs px-3 py-1 rounded'
                onClick={() => setDescription(example.description)}
              >
                {example.title}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className='flex justify-center items-center gap-2'>
        <button
          type='button'
          className='btn-secondary w-40 py-2'
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type='button'
          className='btn-primary w-40 py-2'
          onClick={handleGenerate}
        >
          Generate
        </button>
      </div>
    </div>
  );
}

export function useTriggerCodeGenerator() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback(
    async (props: {
      userRequest: string;
      onGenerate: (description: string) => void;
    }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            borderStyle='gray'
            containerClassName='w-180'
            innerClassName='p-10'
          >
            <CodeGeneratorModal
              userRequest={props.userRequest}
              onCancel={() => {
                p.internalOnCancel();
              }}
              onGenerate={(description) => {
                props.onGenerate(description);
                p.internalOnConfirm();
              }}
            />
          </ModalWrapper>
        ),
      });
    }
  );
}

export function CodeCanvasGenerator(
  props: TrainingSlideEditorProps<CodeCanvasBlock> & { store: GameEditorStore }
) {
  const { block, store } = props;

  const { onChange } = useTrainingSlideEditor(props);
  const triggerModal = useTriggerCodeGenerator();
  const { call: generate } = useLiveAsyncCall(async () => {
    triggerModal({
      userRequest: block.fields.userRequest ?? '',
      onGenerate: async (description) => {
        onChange('userRequest', description);
        onChange('generationStartTime', new Date().toISOString());
        onChange('generationEndTime', null);
        onChange('generating', true);

        const resp = await apiService.block.generateCodeCanvas(block.id, {
          userRequest: description,
        });
        store.blockEditorStore.replaceBlock(fromAPIBlockTypes(resp.data.block));
      },
    });
  });

  if (block.fields.userRequest) {
    return (
      <div className='w-full flex flex-col gap-1'>
        <p className='text-base font-bold'>Canvas Generation Prompt</p>
        <textarea
          className='w-full h-40 p-2.5 field m-0'
          value={block.fields.userRequest}
          disabled
        />
        <div className='flex justify-end items-center gap-2.5 h-10 text-sms -font-medium'>
          <button
            type='button'
            className='btn-secondary px-4 h-full flex justify-center items-center gap-2'
            onClick={() => generate()}
            disabled={block.fields.generating}
          >
            {block.fields.generating ? (
              <Loading
                text='Generating...'
                imgClassName='w-5 h-5'
                containerClassName='h-full'
              />
            ) : (
              <>
                <RefreshIcon className='w-5 h-5 fill-current' />
                <p className='text-sms font-medium'>Re-generate</p>
              </>
            )}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='w-full bg-layer-001 border border-secondary rounded-lg p-4 flex flex-col justify-between items-center min-h-[200px]'>
      <div className='flex flex-col gap-1'>
        <div className='text-base font-bold'>Code Canvas Generator</div>
        <div className='text-sms text-icon-gray'>
          Fill this slide with engaging, visually appealing content by
          describing what you want to present.
        </div>
      </div>
      <button
        type='button'
        className='btn-primary w-30 h-10'
        onClick={() => generate()}
      >
        Start
      </button>
    </div>
  );
}
