import '../../../components/GameV2/design/styles.css';

import { Link, useSearchParams } from '@remix-run/react';
import copy from 'copy-to-clipboard';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useLatest } from 'react-use';
import { useSnapshot } from 'valtio';

import {
  type DtoBlock,
  type DtoGame,
  type DtoGamePack,
  EnumsGamePackChangeLevel,
  type ModelsLogicSettings,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { useLearningAnalytics } from '../../../analytics/learning';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import {
  type FeatureQueryParamArrays,
  useFeatureQueryParam,
} from '../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { apiService } from '../../../services/api-service';
import { SiteBaseURL } from '../../../services/public';
import { BrowserTimeoutCtrl } from '../../../utils/BrowserTimeoutCtrl';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { type GameEditorStore } from '../../Game/GameEditorStore';
import { UGCFileManagerProvider } from '../../Game/UGC';
import { BlockAnimator } from '../../GameV2/apis/BlockAnimationControl';
import { ArrowRightIcon } from '../../icons/Arrows';
import { ChatReplyIcon } from '../../icons/Chat/ChatReplyIcon';
import { FilledCheckIcon } from '../../icons/CheckIcon';
import { CopyLinkIcon } from '../../icons/CopyLinkIcon';
import { DownloadIcon } from '../../icons/DownloadIcon';
import { DuplicateIcon } from '../../icons/DuplicateIcon';
import { HouseIcon } from '../../icons/HouseIcon';
import { LogicIcon } from '../../icons/LogicIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { RenderIcon } from '../../icons/RenderIcon';
import { SettingIcon } from '../../icons/SettingIcon';
import { Loading } from '../../Loading';
import { useOpenLMSLinkExportModal } from '../LMSLinkExport';
import { useOpenScormExportModal } from '../ScormExport';
import { LogicEditor } from './LogicEditor';
import { TrainingEditorAgentChatSidebar } from './TrainingEditorAgentChat';
import {
  AIChatBackground,
  AIChatHeader,
  TrainingEditorAIChatSidebar,
} from './TrainingEditorAIChat';
import {
  TrainingEditorControlAPI,
  TrainingEditorControlAPIProvider,
  useTrainingEditorControlAPI,
} from './TrainingEditorControlAPI';
import {
  TrainingEditorDetails,
  TrainingEditorDetailSidebar,
} from './TrainingEditorDetail';
import { TrainingEditorSettings } from './TrainingEditorSettings';
import { TrainingEditorSidebar } from './TrainingEditorSidebar';
import { TrainingPreview } from './TrainingPreview';
import {
  type TrainingEditorAIChatStatus,
  type TrainingEditorState,
} from './types';
import { TrainingEditorUtils } from './utils';

function NameField(props: {
  value: string;
  onChange: (value: string) => void;
}) {
  const { value, onChange } = props;

  const [editing, setEditing] = useState(false);

  if (!editing)
    return (
      <button
        type='button'
        className='hover:text-white'
        onClick={() => setEditing(true)}
      >
        {value}
      </button>
    );

  return (
    <input
      className='field mb-0 w-50 h-8'
      defaultValue={value}
      onBlur={(e) => {
        onChange(e.target.value);
        setEditing(false);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      }}
      autoFocus
    />
  );
}

function BlockWatcher(props: { store: GameEditorStore; block: Block }) {
  const { store, block } = props;

  const isRendering = TrainingEditorUtils.IsBlockRendering(block);
  const refreshDialogue = useLiveCallback(async () => {
    const resp = await apiService.block.getBlock(block.id);
    const updatedBlock = resp.data.block;
    store.blockEditorStore.replaceBlock(fromAPIBlockTypes(updatedBlock));
  });

  useEffect(() => {
    if (!isRendering) return;

    let intervalId: ReturnType<typeof setInterval> | null = null;
    const timer = setTimeout(() => {
      intervalId = setInterval(refreshDialogue, 30000);
    }, 60000);

    return () => {
      clearTimeout(timer);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRendering, refreshDialogue]);

  return null;
}

function SlideGroupWatcher(props: {
  store: GameEditorStore;
  onRequiresRenderChange: (value: boolean) => void;
  onRenderingChange: (value: boolean) => void;
}) {
  const { store, onRequiresRenderChange, onRenderingChange } = props;
  const blocks = useSnapshot(store.blockEditorStore.state).blocks;

  const requiresRender = useMemo(
    () =>
      blocks.some((b) => TrainingEditorUtils.IsBlockRenderRequired(b as Block)),
    [blocks]
  );
  const isRendering = useMemo(
    () => blocks.some((b) => TrainingEditorUtils.IsBlockRendering(b as Block)),
    [blocks]
  );

  const onRequiresRenderChangeRef = useLatest(onRequiresRenderChange);
  useEffect(() => {
    onRequiresRenderChangeRef.current(requiresRender);
  }, [requiresRender, onRequiresRenderChangeRef]);
  const onRenderingChangeRef = useLatest(onRenderingChange);
  useEffect(() => {
    onRenderingChangeRef.current(isRendering);
  }, [isRendering, onRenderingChangeRef]);

  return (
    <>
      {blocks.map((block) => (
        <BlockWatcher key={block.id} store={store} block={block as Block} />
      ))}
    </>
  );
}

function Watcher(props: {
  stores: GameEditorStore[];
  onRequiresRenderChange: (value: boolean) => void;
  onRenderingChange: (value: boolean) => void;
}) {
  const { stores, onRequiresRenderChange, onRenderingChange } = props;
  const requiresRenderSet = useInstance(() => new Set<string>());
  const isRenderingSet = useInstance(() => new Set<string>());

  const handleRenderAvatars = (id: string, value: boolean) => {
    if (value) {
      requiresRenderSet.add(id);
    } else {
      requiresRenderSet.delete(id);
    }

    onRequiresRenderChange(requiresRenderSet.size > 0);
  };
  const handleRendering = (id: string, value: boolean) => {
    if (value) {
      isRenderingSet.add(id);
    } else {
      isRenderingSet.delete(id);
    }
    onRenderingChange(isRenderingSet.size > 0);
  };

  return (
    <>
      {stores.map((store) => (
        <SlideGroupWatcher
          key={store.state.game?.id}
          store={store}
          onRequiresRenderChange={(value) =>
            handleRenderAvatars(store.state.game?.id ?? '', value)
          }
          onRenderingChange={(value) =>
            handleRendering(store.state.game?.id ?? '', value)
          }
        />
      ))}
    </>
  );
}

function SidebarContainer(props: {
  aiChatStatus: TrainingEditorAIChatStatus;
  slidesNav: React.ReactNode;
  aiChatSidebar: React.ReactNode;
}) {
  const { aiChatStatus, slidesNav, aiChatSidebar } = props;

  const slidesNavRef = useRef<HTMLDivElement>(null);
  const aiChatSidebarRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (aiChatStatus !== 'opening') return;

    slidesNavRef.current?.animate(
      [{ transform: 'translateX(0)' }, { transform: 'translateX(-100%)' }],
      { duration: 500, fill: 'forwards' }
    );
    aiChatSidebarRef.current?.style.setProperty('opacity', '0');
    aiChatSidebarRef.current?.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: 200,
      fill: 'forwards',
      delay: 500,
    });
  }, [aiChatStatus]);

  useLayoutEffect(() => {
    if (aiChatStatus !== 'closing') return;

    slidesNavRef.current?.animate(
      [{ transform: 'translateX(-100%)' }, { transform: 'translateX(0)' }],
      { duration: 500, fill: 'forwards' }
    );
    aiChatSidebarRef.current?.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 500,
      fill: 'forwards',
    });
  }, [aiChatStatus]);

  return (
    <div className='flex-none w-72 h-full relative z-10'>
      {aiChatStatus !== 'open' && (
        <div ref={slidesNavRef} className={`absolute inset-0`}>
          {slidesNav}
        </div>
      )}

      {aiChatStatus !== 'closed' && (
        <div ref={aiChatSidebarRef} className={`absolute inset-0`}>
          {aiChatSidebar}
        </div>
      )}
    </div>
  );
}

type TrainingEditorProps = {
  pack: DtoGamePack;
  games: DtoGame[];
  blocks: DtoBlock[];
  showPreviewTooltip?: boolean;
  homePath: string;
  agentMode: FeatureQueryParamArrays['agentic'][number];
};

export function TrainingEditor(props: TrainingEditorProps) {
  const [searchParams] = useSearchParams();
  const ctrl = useInstance(
    () =>
      new TrainingEditorControlAPI(
        props.pack,
        props.games,
        props.blocks,
        searchParams.get('preview') === 'true'
      )
  );

  useEffect(() => {
    return () => {
      ctrl.dispose();
    };
  }, [ctrl]);

  return (
    <TrainingEditorControlAPIProvider value={ctrl}>
      <TrainingEditorInternal {...props} />
    </TrainingEditorControlAPIProvider>
  );
}

function TrainingEditorInternal(props: TrainingEditorProps) {
  const agenticEnabled = props.agentMode !== 'disabled';

  const [searchParams, setSearchParams] = useSearchParams();

  const ctrl = useTrainingEditorControlAPI();
  const state = useSnapshot(ctrl.state) as unknown as TrainingEditorState;

  const updatePreviewState = (previewState: boolean) => {
    ctrl.setIsPreviewing(previewState);
    if (previewState) {
      searchParams.set('preview', 'true');
    } else {
      searchParams.delete('preview');
    }
    setSearchParams(searchParams, { replace: true });
  };

  const [animator] = useState(() => new BlockAnimator<string>({}, () => null));

  if (state.isPreviewing) {
    return <Preview onClose={() => updatePreviewState(false)} />;
  }

  return (
    <div className='w-full h-full bg-training-editor bg-center bg-no-repeat bg-cover flex flex-col'>
      <Header
        homePath={props.homePath}
        showPreviewTooltip={!!props.showPreviewTooltip}
      />

      <main className={`relative w-full flex-1 overflow-hidden rounded-xl`}>
        {!agenticEnabled && (
          <AIChatBackground aiChatStatus={state.aiChatStatus} />
        )}

        {state.isRebuildingStores && (
          <div className='absolute left-0 top-0 bottom-0 right-72 z-50 bg-black/50 flex justify-center items-center text-white'>
            <Loading text='Loading Changes...' />
          </div>
        )}

        <div
          className={`relative z-5 w-full h-full rounded-xl flex gap-4 ${
            state.aiChatStatus === 'opening' || state.aiChatStatus === 'open'
              ? 'p-3'
              : 'p-0'
          } transition-padding duration-500 pl-2.5`}
        >
          <LeftSideBar agentMode={props.agentMode} animator={animator} />

          <div className='flex-1 h-full flex flex-col'>
            {!agenticEnabled && (
              <AIChatHeader aiChatStatus={state.aiChatStatus} ctrl={ctrl} />
            )}

            <div className='w-full flex-1 overflow-hidden'>
              <Main agentMode={props.agentMode} animator={animator} />
            </div>
          </div>

          <AgentChat agentMode={props.agentMode} />
        </div>
      </main>
    </div>
  );
}

function Header(props: { homePath: string; showPreviewTooltip: boolean }) {
  const ctrl = useTrainingEditorControlAPI();
  const { name } = useSnapshot(ctrl.state.pack);

  const handleChangeName = useLiveCallback(async (value: string) => {
    await ctrl.updateGamePack({
      name: value,
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
    });
  });

  return (
    <header className='w-full h-15 px-7.5 flex-none grid grid-cols-3'>
      <div className='flex items-center gap-2.5 text-sms text-icon-gray'>
        <Link to={props.homePath}>
          <button type='button' className='flex-none hover:text-white'>
            <HouseIcon className='w-4 h-4 fill-current' />
          </button>
        </Link>

        <ArrowRightIcon className='flex-none w-4 h-4 fill-current' />

        <NameField value={name} onChange={handleChangeName} />
      </div>

      <RenderAvatars />

      <div className='flex justify-end items-center gap-5'>
        <SettingsButton />
        <LogicButton />
        <PlayButton {...props} />
        <ShareButton />
      </div>
    </header>
  );
}

function SettingsButton() {
  const ctrl = useTrainingEditorControlAPI();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const handleCourseSettingsEdit = useLiveCallback(async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-165'
          innerClassName='rounded-xl'
          borderStyle='gray'
        >
          <TrainingEditorSettings
            pack={ctrl.state.pack}
            onCancel={p.internalOnCancel}
            onSave={async (change) => {
              p.internalOnConfirm();
              await ctrl.updateGamePack(change);
            }}
          />
        </ModalWrapper>
      ),
    });
  });

  return (
    <button
      type='button'
      className='text-icon-gray hover:text-white'
      onClick={handleCourseSettingsEdit}
    >
      <SettingIcon className='w-4 h-4 fill-current' />
    </button>
  );
}

function LogicButton() {
  const showBlockLogic = useFeatureQueryParam('show-block-logic');
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const ctrl = useTrainingEditorControlAPI();

  const handleLogicEdit = useLiveCallback(async () => {
    const blocks = ctrl.state.stores.flatMap(
      (s) => s.blockEditorStore.state.blocks
    );
    const miniGameNames = new Map<string, string>();
    ctrl.state.stores.forEach(
      (s) =>
        s.state.game && miniGameNames.set(s.state.game.id, s.state.game.name)
    );

    await triggerModal({
      kind: 'custom',
      element: (p) => {
        return (
          <LogicEditor
            defaultValue={ctrl.state.pack.logicSettings}
            blocks={blocks}
            blockId={ctrl.state.selectedStore?.state.selectedBlockId}
            minigameNames={miniGameNames}
            onCancel={p.internalOnCancel}
            onSave={async (logicSettings: ModelsLogicSettings) => {
              p.internalOnConfirm();
              await ctrl.updateGamePack({
                logicSettings,
                changeLevel:
                  EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
              });
            }}
          />
        );
      },
    });
  });

  if (!showBlockLogic) return null;

  return (
    <button
      type='button'
      className='text-icon-gray hover:text-white'
      onClick={handleLogicEdit}
    >
      <LogicIcon />
    </button>
  );
}

function ShareButton() {
  const ctrl = useTrainingEditorControlAPI();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const openLMSLinkExportModal = useOpenLMSLinkExportModal();
  const openScormExportModal = useOpenScormExportModal();

  const handleDownloadScorm = useLiveCallback(() => {
    openScormExportModal(ctrl.state.pack);
    setOpen(false);
  });

  const handleCreatLMSLink = useLiveCallback(() => {
    openLMSLinkExportModal(ctrl.state.pack.id);
    setOpen(false);
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [timer] = useState(() => new BrowserTimeoutCtrl());
  useEffect(() => {
    return () => {
      timer.clear();
    };
  }, [timer]);

  const handleCopyLink = useLiveCallback(() => {
    const url = new SiteBaseURL();
    url.pathname = `/game-packs/${ctrl.state.pack.id}/share`;
    url.searchParams.set('guest-users', 'enabled');

    copy(url.toString(), {
      format: 'text/plain',
    });
    setShowSuccess(true);
    timer.clear();
    timer.set(() => {
      setShowSuccess(false);
    }, 3000);
  });

  useOutsideClick(ref, () => setOpen(false));

  return (
    <div ref={ref} className='relative'>
      <button
        type='button'
        className='flex items-center gap-1.5 text-sms text-icon-gray hover:text-white'
        onClick={() => setOpen((o) => !o)}
      >
        <ChatReplyIcon className='w-4 h-4 fill-current' /> Share
      </button>
      {open && (
        <div className='absolute right-0 top-full mt-1 border border-secondary rounded-lg z-45 bg-black w-max p-1'>
          <button
            type='button'
            className='w-full p-3 hover:bg-secondary flex items-center gap-2 rounded-md cursor-pointer transition-colors text-left'
            onClick={handleCopyLink}
          >
            {showSuccess ? (
              <FilledCheckIcon className='w-3.5 h-3.5 fill-current text-green-001' />
            ) : (
              <CopyLinkIcon className='w-3.5 h-3.5 fill-current text-white' />
            )}
            <div className='text-sms text-white'>Copy Link</div>
          </button>
          <button
            type='button'
            className='w-full p-3 hover:bg-secondary flex items-center gap-2 rounded-md cursor-pointer transition-colors text-left'
            onClick={handleDownloadScorm}
          >
            <DownloadIcon className='w-3.5 h-3.5 fill-current text-white' />
            <div className='text-sms text-white'>Download SCORM</div>
          </button>
          <button
            type='button'
            className='w-full p-3 hover:bg-secondary flex items-center gap-2 rounded-md cursor-pointer transition-colors text-left'
            onClick={handleCreatLMSLink}
          >
            <DuplicateIcon className='w-3.5 h-3.5 fill-current text-white' />
            <div className='text-sms text-white'>Create LMS Link</div>
          </button>
        </div>
      )}
    </div>
  );
}

function PlayButton(props: { showPreviewTooltip: boolean }) {
  const ctrl = useTrainingEditorControlAPI();
  const [showPreviewTooltip, setShowPreviewTooltip] = useState(
    props.showPreviewTooltip
  );

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: null,
      interactive: false,
      visible: showPreviewTooltip,
    });

  const onClick = useLiveCallback(() => {
    setShowPreviewTooltip(false);
    ctrl.setIsPreviewing(true);
  });

  return (
    <>
      <button
        ref={setTriggerRef}
        type='button'
        className='btn-secondary text-white text-sms flex items-center justify-center gap-1.5 h-8 w-[92px] rounded-md'
        onClick={onClick}
      >
        <PlayIcon className='w-4 h-4 fill-current text-luna-primary' />
        Preview
      </button>
      {visible &&
        createPortal(
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: `animate-pulse text-sms font-bold text-black relative flex items-center justify-center w-[212px] h-[37px]`,
            })}
          >
            <svg
              className='absolute inset-0 z-0'
              xmlns='http://www.w3.org/2000/svg'
              width='212'
              height='37'
              viewBox='0 0 212 37'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M111.163 7L106.5 0L101.837 7H8C3.58172 7 0 10.5817 0 15V29C0 33.4183 3.58172 37 7.99999 37H204C208.418 37 212 33.4183 212 29V15C212 10.5817 208.418 7 204 7H111.163Z'
                fill='#FBB707'
              />
            </svg>
            <p className='z-[1] mt-1.5'>Click to Preview Your Course</p>
          </div>,
          document.body
        )}
    </>
  );
}

function RenderAvatars() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const ctrl = useTrainingEditorControlAPI();
  const stores = useSnapshot(ctrl.state).stores;
  const [requiresRender, setRequiresRender] = useState(false);
  const [isRendering, setIsRendering] = useState(false);

  const {
    call: handleRenderAvatars,
    state: {
      state: { isRunning: isRenderRequesting },
    },
  } = useLiveAsyncCall(async () => {
    const stores = ctrl.state.stores;
    const blockIds = stores.flatMap((s) =>
      s.blockEditorStore.state.blocks
        .filter((block) => TrainingEditorUtils.IsBlockRenderRequired(block))
        .map((block) => block.id)
    );

    const { result } = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='p-5 text-white'>
          <div className='text-2xl font-medium text-center'>
            Render Avatar Videos?
          </div>
          <div className='my-4 text-sms'>
            Rendering avatars will take a few minutes. You can continue to edit
            the other parts of your game while the avatars render.
            <br />
            <br />
            Slides with Avatars: {blockIds.length}
          </div>
        </div>
      ),
      confirmBtnLabel: 'Continue',
      confirmBtnVariant: 'primary',
      cancelBtnLabel: 'Cancel',
    });
    if (result === 'canceled') return;

    const resp = await apiService.block.renderBlocks({ blockIds });
    for (const block of resp.data.blocks) {
      const store = stores.find((s) => s.state.game?.id === block.gameId);
      if (!store) continue;
      store.blockEditorStore.replaceBlock(fromAPIBlockTypes(block));
    }
  });

  // TODO(falcon): should we move the "watcher" into the ctrl?
  return (
    <div className='flex justify-center items-center gap-6'>
      <Watcher
        stores={stores as GameEditorStore[]}
        onRequiresRenderChange={setRequiresRender}
        onRenderingChange={setIsRendering}
      />

      {(isRendering || isRenderRequesting) && (
        <div className='flex flex-col items-center gap-1'>
          <div className='text-xs font-medium text-icon-gray'>
            Rendering ... check again in a few minutes.
          </div>
        </div>
      )}
      {isRendering ||
        isRenderRequesting ||
        (requiresRender && (
          <button
            type='button'
            className='btn-luna-primary rounded-md text-sms w-35 h-8 flex justify-center items-center gap-1'
            onClick={handleRenderAvatars}
            disabled={isRenderRequesting || isRendering}
          >
            <RenderIcon className='w-4 h-4 fill-current' />
            Render Avatars
          </button>
        ))}
    </div>
  );
}

function Preview(props: { onClose: () => void }) {
  const analytics = useLearningAnalytics();
  const ctrl = useTrainingEditorControlAPI();
  const { pack, stores, selectedStore } = useSnapshot(
    ctrl.state
  ) as unknown as TrainingEditorState;
  return (
    <TrainingPreview
      pack={pack}
      stores={stores}
      selectedStore={selectedStore}
      defaultDevice='desktop'
      onClose={props.onClose}
      analytics={analytics}
    />
  );
}

function LeftSideBar(props: {
  agentMode: FeatureQueryParamArrays['agentic'][number];
  animator: BlockAnimator;
}) {
  const ctrl = useTrainingEditorControlAPI();
  const analytics = useLearningAnalytics();
  const editorSidebar = useMemo(() => <TrainingEditorSidebar />, []);
  const state = useSnapshot(ctrl.state) as unknown as TrainingEditorState;

  return props.agentMode !== 'disabled' ? (
    <div className='flex-none w-72 h-full relative z-10 flex flex-col gap-1'>
      <div className='flex-1 min-h-0'>{editorSidebar}</div>
      {state.selectedStore ? (
        <TrainingEditorDetailSidebar
          store={state.selectedStore}
          animator={props.animator}
          analytics={analytics}
          className='relative flex-1 w-full overflow-auto scrollbar bg-main-layer rounded-lg p-2.5'
        />
      ) : null}
    </div>
  ) : (
    <SidebarContainer
      aiChatStatus={state.aiChatStatus}
      slidesNav={editorSidebar}
      aiChatSidebar={
        state.selectedStore ? (
          <UGCFileManagerProvider>
            <TrainingEditorAIChatSidebar
              pack={state.pack}
              store={state.selectedStore}
              ctrl={ctrl}
            />
          </UGCFileManagerProvider>
        ) : null
      }
    />
  );
}

function Main(props: {
  agentMode: FeatureQueryParamArrays['agentic'][number];
  animator: BlockAnimator;
}) {
  const ctrl = useTrainingEditorControlAPI();
  const analytics = useLearningAnalytics();
  const state = useSnapshot(ctrl.state) as unknown as TrainingEditorState;
  const agenticEnabled = props.agentMode !== 'disabled';

  if (!state.selectedStore) return null;

  return (
    <TrainingEditorDetails
      pack={state.pack}
      stores={state.stores}
      store={state.selectedStore}
      animator={props.animator}
      analytics={analytics}
      state={state as TrainingEditorState}
      ctrl={ctrl}
      aiChatButtonEnabled={!agenticEnabled}
      sidebar={
        agenticEnabled ? null : (
          <div className='relative flex-none w-72 h-full overflow-auto scrollbar bg-main-layer rounded-lg border border-secondary p-2.5'>
            <TrainingEditorDetailSidebar
              store={state.selectedStore}
              animator={props.animator}
              analytics={analytics}
            />
          </div>
        )
      }
    />
  );
}

function AgentChat(props: {
  agentMode: FeatureQueryParamArrays['agentic'][number];
}) {
  const ctrl = useTrainingEditorControlAPI();
  const { pack } = useSnapshot(ctrl.state) as unknown as TrainingEditorState;
  if (props.agentMode === 'disabled') return null;

  return (
    <div className='relative flex-none w-80 h-full overflow-auto scrollbar bg-main-layer rounded-lg p-2.5'>
      <UGCFileManagerProvider>
        <TrainingEditorAgentChatSidebar
          pack={pack}
          ctrl={ctrl}
          agentMode={props.agentMode}
        />
      </UGCFileManagerProvider>
    </div>
  );
}
